import React,{ useContext } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import { Layout, Button, Typography, Table, Space, Modal, message, Input, Card } from 'antd'
import AuthContext from '../context'
const { Search } = Input


const GET_EVENTS = gql`
  query getEventByOrganizer($organizId: MongoID) {
    eventMany(filter: {
      organizId: $organizId,
      deleted: false
    }) {
      _id,
      name,
      slug
    }
  }
`
const MUATATION_REMOVE_EVENT = gql`
  mutation removeEvent($eventId: MongoID!) {
    removeEvent(eventId: $eventId)
  }
`

const FIND_RUNNER = gql`
query($organizId: MongoID!, $bib: Int){
  findRunnerByOrganize(organizId: $organizId, bib: $bib){
    eventId,
    bib
    race
  }
}
`
const FIND_RUNNER_NAME = gql`
query($organizId: MongoID!, $name: String){
  findRunnerByName(organizId: $organizId, name: $name){
    bib
    eventId
    name
    race
  }
}
`

function EventByOrganiz() {
  const [removeEvent] = useMutation(MUATATION_REMOVE_EVENT)
  const history = useHistory()
  const { organizId } = useParams()
  const { data: event, loading, refetch } = useQuery(GET_EVENTS, { variables: { organizId }, fetchPolicy: 'cache-and-network' })
  const { userId } = useContext(AuthContext)
  const isAdmin = userId === 'fnywEdRHF0SmOAUUrfJmmGV1nv73'
  const { refetch: runnerRefetch } = useQuery(FIND_RUNNER, { variables: { organizId, bib: 1 } })
  const { refetch: refetchSearchName } = useQuery(FIND_RUNNER_NAME, { variables: { organizId, name: '' } })

  const handleRemoveEvent = (eventId) => {
    Modal.confirm({
      title: 'คุณจะลบการแข่งขันนี้ใช่หรือไม่',
      async onOk() {
        await removeEvent({ variables: { eventId }})
        message.success('ลบสำเร็จ')
        refetch()
      },
      onCancel() { return }
    })
  }
  
  const columns = [
    {
      title: 'ชื่องาน',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'slug',
      dataIndex: 'slug',
      key: 'slug'
    }, {
      title: 'จัดการ',
      dataIndex: '_id',
      key: '_id',
      render: _id => {
        return (
          <Space>
            <Button onClick={() => history.push(`/dashboard/${organizId}/${_id}`)}>เช็คพอยท์</Button>
            <Button onClick={() => history.push(`/running/${organizId}/${_id}`)}>เช็คพอยท์ล่าสุด</Button>
            <Button onClick={() => history.push(`/results/${organizId}/${_id}`)}>ผลการแข่งขัน</Button>
            <Button onClick={() => history.push(`/buff/${organizId}/${_id}`)}>Buffet 24 hours</Button>
            <Button onClick={() => history.push(`/alc/${organizId}/${_id}`)}>A la Carte 1</Button>
            <Button onClick={() => history.push(`/alc2/${organizId}/${_id}`)}>A la Carte 2</Button>
            <Button onClick={() => history.push(`/suan/${organizId}/${_id}`)}>42k</Button>
            <Button onClick={() => history.push(`/team/${organizId}/${_id}`)}>ทีม</Button>
            <Button onClick={() => history.push(`/runner/${organizId}/${_id}`)}>รายชื่อนักวิ่ง</Button>
            {isAdmin && 
            <><Button onClick={() => history.push(`/manage-event/edit/${organizId}/${_id}`)}>แก้ไข</Button>
            <Button onClick={() => handleRemoveEvent(_id)}>ลบ</Button></>
            }
          </Space>
        )
      }
    }
  ]
  
  if (loading) return <div>loading events...</div>
  
  const { eventMany: events } = event

  const handleSearch = async value => {
    await runnerRefetch({organizId:organizId, bib: parseInt(value)}).then((res)=>{
      if(res.data.findRunnerByOrganize.length > 0){
        history.push(`/participant/${organizId}/${res.data.findRunnerByOrganize[0].eventId}/${value}`)
      }
      else {
        Modal.error({
          title: 'ไม่พบหมายเลขที่ต้องหารค้นหา'
        })
      }
    })
  }
 
  const handleSearchName = async value => {
    await refetchSearchName({organizId:organizId, name: value}).then((res)=>{
      if(res.data.findRunnerByName.length === 1){
        history.push(`/participant/${organizId}/${res.data.findRunnerByName[0].eventId}/${res.data.findRunnerByName[0].bib}`)
      } else if(res.data.findRunnerByName.length > 1){
        // history.push(`/results/${organizId}/${res.data.findRunnerByOrganize[0].eventId}/?s=${value}`)
        Modal.info({
          title: 'ผลการค้นหา',
          content: (
            <>
              {res.data.findRunnerByName.map(e => <p><a href={`/participant/${organizId}/${e.eventId}/${e.bib}`}>{e.name}</a></p>)}
            </>
          )
        })
      }
      else {
        Modal.error({
          title: 'ไม่พบชื่อที่ต้องหารค้นหา'
        })
      }
    })
  }

  return (
    <Layout>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <Typography.Title className='fontSukhumvit'>รายการแข่งขัน</Typography.Title>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Search placeholder="ค้นหาจาก bib" style={{ width: 200 }} onSearch={handleSearch} />
        <Search placeholder="ค้นหาจาก ชื่อ" style={{ width: 200, marginTop: '5px' }} onSearch={handleSearchName} />
      </div>
      </div>
      <div style={{paddingBottom : '10px'}}><Button style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px'}} onClick={() => history.push(`/manage-event/add/${organizId}`)}>สร้างการแข่งขัน</Button></div>
      <Card style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px', borderRadius: '8px' }}>
        <Table rowKey={record => record._id} columns={columns} dataSource={events} pagination={{ pageSize: 100 }}/>
      </Card>
    </Layout>
  )
}

export default EventByOrganiz